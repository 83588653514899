import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop, cilDrop, cilLockLocked, cilSearch, cilInfo } from '@coreui/icons'
import { CBadge, CCard, CCardBody, CCardSubtitle, CCardText, CCardTitle, CTooltip } from '@coreui/react';
import Swal from 'sweetalert2';
import { Col, Row } from 'reactstrap';


let userData = JSON.parse(localStorage.getItem("userDataStore"));

const TeamList = () => {
    // console.log("user ", userData?.kyc_redirect)
    const [businessName, setBusinessName] = React.useState( userData?.business_name?.toUpperCase() )

    if(!userData){
        window.location.href = "/login"
    }
    if(userData?.team_list?.length < 2){
        switchActionAccount(userData?.team_list[0], false)

    }
    function switchActionAccount(dataAccount, IsSelected){
        let resetUserData = {
            ...userData, 
            ...{
                "business_name": dataAccount.business_name, 
                "account": dataAccount?.team_account_id, 
                "role_id": dataAccount?.role_id,
                "permission_list": dataAccount?.permission_list || dataAccount?.permisision_list
              }
          };
  
      setBusinessName(dataAccount?.business_name?.toUpperCase() )
  
      localStorage.setItem("userDataStore", JSON.stringify(resetUserData));
  
      if(IsSelected){
            Swal.fire({
                title: 'Business Selected',
                html: "<div class='pb-0 pt-0'> To " + dataAccount.business_name + " </div>",
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                // cancelButtonColor: '#d33',
                timer: 2000
            }).then((result) => {
                // 
                window.location.href = '/dashboard';
            })
        }
        else if(userData?.kyc_redirect === true){
            // check whether the kyc has been completed if not here
            window.location.href = "/compliance";   
        }
        else{
            window.location.href = '/dashboard';
        }
    }

    window.onclick = function (event) {
        // event.preventDefault()
        trackActivity();
      }
      function trackActivity() {
        // e.preventDefault();
        // getSessionTimeout();
        const currentUser_new = JSON.parse(localStorage.getItem("userDataStore"));    
        if(currentUser_new){
          currentUser_new["timeLogout"] = new Date().getTime() + currentUser_new?.counter;
          localStorage.setItem('userDataStore', JSON.stringify(currentUser_new))
        }
      }
    return (
        <div>
            
            {/* {(userData?.firstname + " " + userData?.lastname)?.toUpperCase() }  */}
            <Row>
                <Col md="4" lg="4" xl="4" > </Col>
                <Col xm="12" sm="12" md="4" lg="4" xl="4" style={{textAlign: "center", marginTop: "90px" }} >       
                <h5> LIST OF BUSINESS(S) </h5>
                </Col>
                <Col md="4" lg="4" xl="4" > </Col>
            </Row>
            
            <Box sx={{ width: '100%', marginTop: "60px" }}>
                {/* .filter( (fil) => fil.business_name?.toUpperCase() != businessName?.toUpperCase()) */}
                {
                    userData?.team_list?.length > 0 ?
                        (
                            userData?.team_list?.length > 0 ?
                                userData?.team_list?.map((post, id) =>

                                    <Row key={id} className="mb-3">
                                        <Col md="4" lg="4" xl="4" > </Col>
                                        <Col xm="12" sm="12" md="4" lg="4" xl="4" style={{ cursor: "pointer" }}>
                                            <CCard onClick={(e) => switchActionAccount(post, true)} >
                                                <CCardBody>
                                                    <CCardSubtitle className=" text-medium-emphasis" style={{ textAlign: "center" }}>
                                                        {/*  */}
                                                        {post?.business_name?.toUpperCase()}
                                                    </CCardSubtitle>
                                                </CCardBody>
                                            </CCard>
                                        </Col>
                                        <Col md="4" lg="4" xl="4" > </Col> 
                                    </Row>
                                )
                                :
                                <div spacing={2}>
                                    {/*  */}
                                </div>
                        ) : ""
                }
            </Box>

            <Typography component="h6"  style={{textAlign: "center", fontSize: "15px", margin: "60px auto"}}>
                    Powered by <img src='https://wingipay.com/static/wingipay/logo/wingi-pay-favicorn.998e2836f609.png' style={{margin: "-2px -2px 0px 0px"}}/> WingiPay &copy; {(new Date()).getUTCFullYear()}
            </Typography>
            
        </div>
    );
};

export default TeamList;